import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.related-solutions__container');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,

        breakpoints: {
          1300: {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlides: false,
            loop: false,
          },
        },
      });
    });
  }
});
